import { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import Card from 'src/components/streametric/card/Card';
import SvgIcon from '@mui/icons-material/Search';
import { Button, Chip, Stack, Typography, useTheme } from '@mui/material';
import DesktopFilters from './DesktopFilters';
import DeleteIcon from '@mui/icons-material/Delete';
import { FilterItem as FilterItemType } from '../types';
import FiltersDrawer from './FiltersDrawer';
import { useResponsive } from 'src/hooks';
import MobileFilters from './MobileFilters';
import { renderTitleIcon } from 'src/features/alarm-pop-up/utils';
import FilterItem from './FilterItem';
import DateRangeFilter from './DateRangeFilter';
import dayjs from 'dayjs';
import AssetsFilter from './AssetsFilter';
import { SitesService } from 'src/services/sites';
import { useAuthentication } from 'src/features/authentication/context';
import { uniqBy } from 'lodash';

const LogFilters: FunctionComponent<{
  onChange: (filters: FilterItemType[]) => void;
}> = ({ onChange }) => {
  const theme = useTheme();

  const [isFormsLoading, setIsFormsLoading] = useState(true);

  const {
    customerId: { value: customerId },
    siteId: { value: siteId },
  } = useAuthentication();

  const logGroup = useMemo(
    () => ({
      name: 'event_type',
      label: 'Event type',
    }),
    []
  );

  const alarmStatusGroup = {
    name: 'alarm_status',
    label: 'Status',
  };
  const alarmTypeGroup = {
    name: 'alarm_type',
    label: 'Alarm type',
  };
  const acknowledgementGroup = {
    name: 'alarm_acknowledgement',
    label: 'Acknowledgement',
  };
  const auditTrial = {
    name: 'audit_trial',
    label: 'Audit Trial',
  };
  const assets = {
    name: 'assets',
    label: 'Assets',
  };
  const timeFrame = {
    name: 'time_frame',
    label: 'Time Frame',
  };

  const [openFiltersDrawer, setOpenFiltersDrawer] = useState(false);

  const isMobile = useResponsive('down', 'md');

  const [filtersState, setFiltersState] = useState<FilterItemType[]>([
    {
      name: 'alarm',
      label: 'Alarm',
      component: FilterItem,
      value: false,
      group: logGroup,
    },

    {
      name: 'time_frame',
      label: 'Time Frame',
      component: DateRangeFilter,
      group: timeFrame,
      renderChip: (filter) => {
        const filterDates = filter.value as [string, string];

        return (
          <Chip
            size="small"
            color="primary"
            key={`${filter.name}`}
            label={`${dayjs(filterDates[0]).format('YYYY-MM-DD')} - ${dayjs(filterDates[1]).format(
              'YYYY-MM-DD'
            )}`}
            onDelete={() => {
              onChange(
                parseFilters(
                  filtersState.map((filterS) => {
                    console.log(filterS.name, filter.name);
                    return filterS.name === filter.name ? { ...filterS, value: null } : filterS;
                  })
                )
              );
              setFiltersState((prevState) =>
                prevState.map((filterS) =>
                  filterS.name === filter.name ? { ...filterS, value: null } : filterS
                )
              );
            }}
          />
        );
      },

      value: '',
    },
    {
      name: 'active',
      component: FilterItem,
      label: 'Active',
      value: false,
      group: alarmStatusGroup,
    },
    {
      name: 'cleared',
      component: FilterItem,
      label: 'Cleared',
      value: false,
      group: alarmStatusGroup,
    },
    {
      name: 'Critical',
      component: FilterItem,
      renderChip: (filter) => (
        <Chip
          size="small"
          color="primary"
          key={`${filter.name}`}
          label="Critical"
          onDelete={() => {
            onChange(
              parseFilters(
                filtersStateRef.current.map((filterS) =>
                  filterS.name === filter.name ? { ...filterS, value: null } : filterS
                )
              )
            );
            setFiltersState((prevState) =>
              prevState.map((filterS) =>
                filterS.name === filter.name ? { ...filterS, value: null } : filterS
              )
            );
          }}
        />
      ),
      label: (
        <Stack component="label" htmlFor="critical" flexDirection="row" alignItems="center">
          {renderTitleIcon(
            {
              priority: 'Critical',
            } as any,
            theme,
            theme.palette.error.main
          )}
          <Typography sx={{ marginLeft: '4px' }} variant="body1">
            Critical
          </Typography>
        </Stack>
      ),
      value: false,
      group: alarmTypeGroup,
    },
    {
      name: 'Warning',
      component: FilterItem,
      renderChip: (filter) => (
        <Chip
          size="small"
          color="primary"
          key={`${filter.name}`}
          label="Warning"
          onDelete={() => {
            onChange(
              parseFilters(
                filtersStateRef.current.map((filterS) =>
                  filterS.name === filter.name ? { ...filterS, value: null } : filterS
                )
              )
            );
            setFiltersState((prevState) =>
              prevState.map((filterS) =>
                filterS.name === filter.name ? { ...filterS, value: null } : filterS
              )
            );
          }}
        />
      ),
      label: (
        <Stack component="label" htmlFor="warning" flexDirection="row" alignItems="center">
          {renderTitleIcon(
            {
              priority: 'Warning',
            } as any,
            theme,
            theme.palette.warning.main
          )}
          <Typography sx={{ marginLeft: '4px' }} variant="body1">
            Warning
          </Typography>
        </Stack>
      ),
      value: false,
      group: alarmTypeGroup,
    },
    {
      name: 'Info',
      component: FilterItem,
      renderChip: (filter) => (
        <Chip
          size="small"
          color="primary"
          key={`${filter.name}`}
          label="Info"
          onDelete={() => {
            onChange(
              parseFilters(
                filtersStateRef.current.map((filterS) =>
                  filterS.name === filter.name ? { ...filterS, value: null } : filterS
                )
              )
            );
            setFiltersState((prevState) =>
              prevState.map((filterS) =>
                filterS.name === filter.name ? { ...filterS, value: null } : filterS
              )
            );
          }}
        />
      ),
      label: (
        <Stack component="label" htmlFor="info" flexDirection="row" alignItems="center">
          {renderTitleIcon(
            {
              priority: 'Info',
            } as any,
            theme
          )}
          <Typography sx={{ marginLeft: '4px' }} variant="body1">
            Info
          </Typography>
        </Stack>
      ),
      value: false,
      group: alarmTypeGroup,
    },
    {
      name: 'Offline',
      component: FilterItem,
      renderChip: (filter) => (
        <Chip
          size="small"
          color="primary"
          key={`${filter.name}`}
          label="Offline"
          onDelete={() => {
            onChange(
              parseFilters(
                filtersStateRef.current.map((filterS) =>
                  filterS.name === filter.name ? { ...filterS, value: null } : filterS
                )
              )
            );
            setFiltersState((prevState) =>
              prevState.map((filterS) =>
                filterS.name === filter.name ? { ...filterS, value: null } : filterS
              )
            );
          }}
        />
      ),
      label: (
        <Stack component="label" htmlFor="info" flexDirection="row" alignItems="center">
          {renderTitleIcon(
            {
              priority: 'Offline',
            } as any,
            theme
          )}
          <Typography sx={{ marginLeft: '4px' }} variant="body1">
            Offline
          </Typography>
        </Stack>
      ),
      value: false,
      group: alarmTypeGroup,
    },
    {
      name: 'Cleared',
      component: FilterItem,
      renderChip: (filter) => (
        <Chip
          size="small"
          color="primary"
          key={`${filter.name}`}
          label="Cleared"
          onDelete={() => {
            console.log(filtersState, filter.name);
            onChange(
              parseFilters(
                filtersStateRef.current.map((filterS) =>
                  filterS.name === filter.name ? { ...filterS, value: null } : filterS
                )
              )
            );
            setFiltersState((prevState) =>
              prevState.map((filterS) =>
                filterS.name === filter.name ? { ...filterS, value: null } : filterS
              )
            );
          }}
        />
      ),
      label: (
        <Stack component="label" htmlFor="info" flexDirection="row" alignItems="center">
          {renderTitleIcon(
            {
              eventStatus: 'cleared',
            } as any,
            theme
          )}
          <Typography sx={{ marginLeft: '4px' }} variant="body1">
            Cleared
          </Typography>
        </Stack>
      ),
      value: false,
      group: alarmTypeGroup,
    },
    {
      name: 'acknowledged',
      component: FilterItem,
      label: 'Acknowledged',
      value: false,
      group: acknowledgementGroup,
    },
    {
      name: 'unacknowledged',
      label: 'Unacknowledged',
      component: FilterItem,
      value: false,
      group: acknowledgementGroup,
    },
    {
      name: 'deleted_logs',
      label: 'Deleted logs',
      component: FilterItem,
      value: false,
      group: auditTrial,
    },
    {
      name: 'assets',
      label: 'Assets',
      component: AssetsFilter,
      value: [],
      group: assets,
      renderChip: (filter) =>
        Array.isArray(filter.value) && filter.value.length ? (
          filter.value.map((value: any) => (
            <Chip
              size="small"
              color="primary"
              key={`${filter.name} - ${value}`}
              label={(value as any).assetName}
              onDelete={() => {
                onChange(
                  parseFilters(
                    filtersState.map((filterS) =>
                      filterS.name === filter.name
                        ? {
                            ...filterS,
                            value: (filter!.value as string[]).filter(
                              (el: any) => el.id !== value.id
                            ),
                          }
                        : filterS
                    )
                  )
                );
                setFiltersState((prevState) =>
                  prevState.map((filterS) =>
                    filterS.name === filter.name
                      ? {
                          ...filterS,
                          value: (filterS!.value as string[]).filter(
                            (el: any) => el.id !== value.id
                          ),
                        }
                      : filterS
                  )
                );
              }}
            />
          ))
        ) : (
          <></>
        ),
    },
  ]);

  const filtersStateRef = useRef(filtersState);

  useEffect(() => {
    filtersStateRef.current = filtersState;
  }, [filtersState]);

  useEffect(() => {
    (async () => {
      setIsFormsLoading(true);
      try {
        const data = await SitesService.getAssignedForms(customerId!, siteId!);

        if (Array.isArray(data) && data.length) {
          setFiltersState((prevState) =>
            uniqBy(
              [
                ...prevState,
                ...data.map((form) => ({
                  name: form.id,
                  label: form.name,
                  component: FilterItem,
                  value: false,
                  group: logGroup,
                })),
              ],
              'name'
            )
          );
        }

        setIsFormsLoading(false);
      } catch (err) {
        console.error(err);
        setIsFormsLoading(false);
      }
    })();
  }, [customerId, siteId, setFiltersState, setIsFormsLoading, logGroup]);

  const appliedFilters = filtersState.filter((filter) =>
    Array.isArray(filter.value) ? !!filter.value.length : !!filter.value
  );
  const parseFilters = (filters: FilterItemType[]) =>
    filters.filter((filter) =>
      Array.isArray(filter.value) ? !!filter.value.length : !!filter.value
    );

  return (
    <>
      <FiltersDrawer
        onClose={() => {
          setOpenFiltersDrawer(false);
        }}
        isFormsLoading={isFormsLoading}
        open={openFiltersDrawer}
        filters={filtersState}
        updateFilters={(filters) => {
          onChange(parseFilters(filters));
          setFiltersState(filters);
          setOpenFiltersDrawer(false);
        }}
      />
      <Stack>
        {isMobile ? (
          <MobileFilters
            openFilters={() => {
              setOpenFiltersDrawer(true);
            }}
          />
        ) : (
          <DesktopFilters
            openFilters={() => {
              setOpenFiltersDrawer(true);
            }}
          />
        )}
        {appliedFilters.length ? (
          <Card
            sx={{
              flexShrink: 0,
              color: theme.palette.grey[500],
              marginInline: '0',
              maxWidth: '100%',
              width: '100%',
              position: 'relative',
            }}
          >
            <Stack marginTop="12px" flexDirection={isMobile ? 'column' : 'row'}>
              <Stack flex="1" alignItems={'center'} flexDirection={'row'}>
                <Typography sx={{ color: theme.palette.grey[800] }} variant="body1">
                  Filters &nbsp;
                </Typography>
                <Stack flexDirection="row" flexWrap="wrap" gap="10px" flex="1">
                  {appliedFilters.map((filter) =>
                    filter.renderChip ? (
                      filter.renderChip(filter)
                    ) : (
                      <Chip
                        size="small"
                        color="primary"
                        key={filter.name}
                        label={filter.label}
                        onDelete={() => {
                          onChange(
                            parseFilters(
                              filtersState.map((filterS) =>
                                filterS.name === filter.name ? { ...filterS, value: null } : filterS
                              )
                            )
                          );
                          setFiltersState((prevState) =>
                            prevState.map((filterS) =>
                              filterS.name === filter.name ? { ...filterS, value: null } : filterS
                            )
                          );
                        }}
                      />
                    )
                  )}
                </Stack>
              </Stack>
              <Button
                onClick={() => {
                  setFiltersState((prevState) =>
                    prevState.map((filter) => ({ ...filter, value: null }))
                  );
                  onChange(
                    parseFilters(filtersState.map((filterS) => ({ ...filterS, value: null })))
                  );
                }}
                sx={{ alignSelf: 'end', flexShrink: 0, color: theme.palette.grey[600] }}
                variant="text"
              >
                <Stack gap="4px" alignItems="center" flexDirection="row">
                  <SvgIcon fontSize="small" component={DeleteIcon} />
                  <Typography variant="caption">Clear all</Typography>
                </Stack>
              </Button>
            </Stack>
          </Card>
        ) : (
          <></>
        )}
      </Stack>
    </>
  );
};

export default LogFilters;
