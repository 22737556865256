import { api, READINGS, PUMP_REPORT, REPORTS, API_V2, CUSTOMERS, TEMPLATES } from '../api';
import { GetAllByRangeParams, GetAllTemplates } from './types';

const getAll = async (customerId: string, startDate: string, endDdate: string) => {
  try {
    const response = await api({
      method: 'get',
      url: `v2/reports?customerId=${customerId}&startDate=${startDate}&endDate=${endDdate}&reportType=pump_report`,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

const getAllTemplates = async ({ customerId, createdBy, isFavorite, search }: GetAllTemplates) => {
  const queryParams = new URLSearchParams();
  if (createdBy) queryParams.append('createdBy', createdBy);
  if (isFavorite) queryParams.append('isFavorite', String(isFavorite));
  if (search) queryParams.append('search', search);

  try {
    const response = await api({
      method: 'get',
      url: `${API_V2}/${CUSTOMERS}/${customerId}/${TEMPLATES}?${queryParams.toString()}`,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const getHistoryTemplates = async (customerId: string, templateId: string, reportType: string) => {
  try {
    const reponse = await api({
      method: 'get',
      url: `${API_V2}/${CUSTOMERS}/${customerId}/${TEMPLATES}/${
        reportType === 'DATA' ? 'data-reports' : 'asset-reports'
      }/${templateId}/${REPORTS}`,
    });

    return reponse.data;
  } catch (error) {
    console.error(error);
  }
};

const getDataHistoryTemplateByInstanceId = async (customerId: string, reportId: string) => {
  try {
    const response = await api({
      method: 'get',
      url: `${API_V2}/${CUSTOMERS}/${customerId}/${REPORTS}/data-reports/${reportId}`,
    });

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const getAssetHistoryTemplateByInstanceId = async (customerId: string, reportId: string) => {
  try {
    const response = await api({
      method: 'get',
      url: `${API_V2}/${CUSTOMERS}/${customerId}/${REPORTS}/asset-reports/${reportId}`,
    });

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const updateTemplateById = async (templateId: string, customerId: string, data: any) => {
  try {
    const response = await api({
      method: 'patch',
      url: `${API_V2}/${CUSTOMERS}/${customerId}/${TEMPLATES}/asset-reports/${templateId}`,
      data,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const exportAssetReport = async ({
  customerId,
  data,
  format,
  templateId,
}: {
  customerId: string;
  data?: any;
  format: 'excel' | 'csv';
  templateId?: string | null;
}) => {
  const mimeType =
    format === 'excel'
      ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      : 'text/csv';

  const response = await api({
    method: 'POST',
    url: `${API_V2}/${CUSTOMERS}/${customerId}/${REPORTS}/asset-reports/export-request${
      templateId ? `?templateId=${templateId}` : ''
    }`,
    responseType: 'blob',
    headers: {
      'media-type': mimeType,
    },
    data: data,
  });

  const contentDisposition = response.headers['Content-Disposition'];

  let fileName = 'download'; // Default name if not found
  if (contentDisposition) {
    const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
    if (fileNameMatch && fileNameMatch[1]) {
      fileName = fileNameMatch[1];
    }
  }

  const blob = new Blob([response.data], { type: mimeType });

  // Step 2: Create a download link
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName; // Set the file name for the download

  // Step 3: Programmatically click the link to trigger the download
  document.body.appendChild(link);
  link.click();

  // Step 4: Clean up and remove the link
  document.body.removeChild(link);
  return response.data;
};

const exportDataReport = async ({
  customerId,
  data,
  format,
  reportId,
}: {
  customerId: string;
  data?: any;
  format: 'excel' | 'csv';
  reportId?: string | null;
}) => {
  const mimeType =
    format === 'excel'
      ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      : 'text/csv';

  const response = await api({
    method: 'POST',
    url: `${API_V2}/${CUSTOMERS}/${customerId}/${REPORTS}/data-reports/export-request`,
    responseType: 'blob',
    headers: {
      'media-type': mimeType,
    },
    data: {
      dataReportTemplate: data,
      reportId: reportId,
    },
  });

  const contentDisposition = response.headers['Content-Disposition'];

  let fileName = 'download'; // Default name if not found
  if (contentDisposition) {
    const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
    if (fileNameMatch && fileNameMatch[1]) {
      fileName = fileNameMatch[1];
    }
  }

  const blob = new Blob([response.data], { type: mimeType });

  // Step 2: Create a download link
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName; // Set the file name for the download

  // Step 3: Programmatically click the link to trigger the download
  document.body.appendChild(link);
  link.click();

  // Step 4: Clean up and remove the link
  document.body.removeChild(link);
  return response.data;
};

const getCount = async (customerId: string) => {
  try {
    const response = await api({
      method: 'get',
      url: `${API_V2}/${CUSTOMERS}/${customerId}/${TEMPLATES}/count`,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

const saveReportTemplate = async (customerId: string, data: any) => {
  const response = await api({
    method: 'POST',
    url: `v2/customers/${customerId}/templates/${
      data.reportType === 'DATA' ? 'data-reports' : 'asset-reports'
    }`,
    data,
  });
  return response.data;
};

const deleteTemplate = async (customerId: string, templateId: string, reportType: string) => {
  const response = await api({
    method: 'DELETE',
    url: `v2/${CUSTOMERS}/${customerId}/${TEMPLATES}/${
      reportType === 'DATA' ? 'data-reports' : 'asset-reports'
    }/${templateId}`,
  });
  return response.data;
};

const deleteDataReportHistoryItem = async (customerId: string, reportId: string) => {
  const response = await api({
    method: 'DELETE',
    url: `v2/${CUSTOMERS}/${customerId}/reports/data-reports/${reportId}`,
  });
  return response.data;
};

const deleteAssetReportHistoryItem = async (customerId: string, reportId: string) => {
  const response = await api({
    method: 'DELETE',
    url: `v2/${CUSTOMERS}/${customerId}/reports/asset-reports/${reportId}`,
  });
  return response.data;
};

const saveDataReport = async (customerId: string, data: { dataReportTemplateId: string }) => {
  const response = await api({
    method: 'post',
    url: `${API_V2}/${CUSTOMERS}/${customerId}/${REPORTS}/data-reports`,
    data,
  });
  return response.data;
};

const saveAssetReport = async (
  customerId: string,
  data: { templateId: string; reportId: string }
) => {
  const response = await api({
    method: 'post',
    url: `${API_V2}/${CUSTOMERS}/${customerId}/${REPORTS}/asset-reports`,
    data,
  });
  return response.data;
};

const updateReportTemplate = async (templateId: string, customerId: string, data: any) => {
  const response = await api({
    method: 'PATCH',
    url: `v2/customers/${customerId}/templates/${
      data.reportType === 'DATA' ? 'data-reports' : 'asset-reports'
    }/${templateId}`,
    data,
  });
  return response.data;
};
const getReportTemplateById = async (
  reportType: string,
  customerId: string,
  templateId: string
) => {
  const response = await api({
    method: 'GET',
    url: `v2/${CUSTOMERS}/${customerId}/${TEMPLATES}/${
      reportType === 'DATA' ? 'data-reports' : 'asset-reports'
    }/${templateId}`,
  });
  return response.data;
};

const previewReport = async (
  customerId: string,
  data: any,
  controller: AbortController,
  reportType: string
) => {
  const response = await api({
    method: 'POST',
    signal: controller.signal,
    url: `v2/customers/${customerId}/reports/${
      reportType === 'DATA' ? 'data-reports' : 'asset-reports'
    }/generator`,
    data: reportType === 'DATA' ? { dataReportTemplate: data } : data,
  });
  return response.data;
};

const getAllByRange = async (params: GetAllByRangeParams) => {
  try {
    const response = await api({
      method: 'get',
      url: `${READINGS}/${params.customerId}${PUMP_REPORT}/between?fromDate=${params.fromDate}&toDate=${params.toDate}&limit=${params.limit}`,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const ReportsService = {
  getAll,
  getCount,
  getAllTemplates,
  getHistoryTemplates,
  getDataHistoryTemplateByInstanceId,
  getAssetHistoryTemplateByInstanceId,
  getAllByRange,
  saveReportTemplate,
  saveDataReport,
  saveAssetReport,
  previewReport,
  updateTemplateById,
  deleteTemplate,
  updateReportTemplate,
  exportDataReport,
  exportAssetReport,
  getReportTemplateById,
  deleteDataReportHistoryItem,
  deleteAssetReportHistoryItem,
};
