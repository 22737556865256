import { FunctionComponent } from 'react';
import { FilterComponentProps } from '../types';
import { Stack } from '@mui/material';
// import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRangePicker } from 'rsuite';
import '../styles.css';

const DateRangeFilter: FunctionComponent<FilterComponentProps> = ({ name, value, onChange }) => (
  <Stack flexDirection="row" gap="10px">
    <DateRangePicker
      ranges={[]}
      placement="bottomEnd"
      cleanable
      value={
        Array.isArray(value) && value.length === 2
          ? [new Date(value[0] as string), new Date(value[1] as string)]
          : null
      }
      onChange={(dates) =>
        onChange(
          dates?.map((date) => date.toISOString()),
          name
        )
      }
    />
  </Stack>
);

export default DateRangeFilter;
