import * as Yup from 'yup';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Card, Grid, Stack, TextField, Typography, useTheme } from '@mui/material';
import {
  FormProvider,
  RHFAutocomplete,
  RHFSelect,
  RHFTextField,
} from 'src/components/minimals/form';
import { SITES_MANAGEMENT_PATH } from 'src/routes/config';
import { formatUTCDateToLocal } from 'src/utilities';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { CountryType } from 'src/features/customer-account-details/types/country-type';
import { SiteType } from 'src/features/sites/types/site-type';
import { NewSiteWithActualSiteType, Site, UpdateSite } from '../types/site';
import { timeZone } from 'src/features/user-account-details/types/time-zone';
import { SitesService } from 'src/services/sites';
import { useSnackbar } from 'notistack';
import { EDIT_SITE_SNACKBAR_SUCCESS_LABEL } from 'src/features/user-account-details/config';
import { useResponsive } from 'src/hooks';
import { useAuthentication } from 'src/features/authentication/context';
import { AccessRoles } from 'src/features/user-account-details/types';
import { ArrowDownIcon } from 'src/assets';

type AddSiteFormProps = {
  handleConfirmationWindow: () => void;
  isEdit?: boolean;
  currentSite?: Site;
};

export const SiteDetailsForm = ({
  handleConfirmationWindow,
  isEdit = false,
  currentSite,
}: AddSiteFormProps) => {
  const [lastEdited, setLastEdited] = useState('');
  const countries: CountryType[] = require('../../shared-data/countries.json');
  const siteTypes: SiteType[] = require('../data/site-type.json');
  const timeZones: timeZone[] = require('../data/time-zones.json');
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const {
    customerId: { value: customerId },
    siteId: { value: siteId },
    getCurrentRole,
  } = useAuthentication();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const allowedToEdit = siteId && isEdit;
  const isMobile = useResponsive('down', 'sm');
  const isSiteEmployee = getCurrentRole(customerId, siteId) === AccessRoles.SITE_EMPLOYEE;

  const SiteSchema = Yup.object().shape({
    siteName: Yup.string()
      .required('Site Name is required')
      .max(50, 'The maximum number of characters for a Site Name is 50'),
    siteType: Yup.string().required('Site Type is required'),
    location: Yup.object().shape({
      street: Yup.string().notRequired(),
      city: Yup.string().required('City is required'),
      country: Yup.string().notRequired(),
      state: Yup.string().required('State is required'),
      zip: Yup.string().notRequired(),
      altitude: Yup.string().notRequired(),
      longitude: Yup.string().notRequired(),
      latitude: Yup.string().notRequired(),
      timeZone: Yup.string().notRequired(),
    }),
  });

  const defaultValues = useMemo(
    () => ({
      siteName: currentSite?.siteName || '',
      siteType: currentSite?.siteType || siteTypes[0].value,
      location: {
        street: currentSite?.location?.street || '',
        city: currentSite?.location?.city || '',
        country: currentSite?.location?.country || countries[0].label,
        state: currentSite?.location?.state || '',
        zip: currentSite?.location?.zip || '',
        altitude: currentSite?.location?.altitude || '',
        longitude: currentSite?.location?.longitude || '',
        latitude: currentSite?.location?.latitude || '',
        timeZone: currentSite?.location?.timeZone || timeZones[0].value,
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentSite]
  );

  const createNewSite = async (data: NewSiteWithActualSiteType) => {
    if (!customerId) {
      enqueueSnackbar('CustomerId is missing', {
        variant: 'error',
      });
      return;
    }
    if (error) {
      enqueueSnackbar('Site name invalid', {
        variant: 'error',
      });
      return;
    }
    try {
      await SitesService.add(data, customerId);
      handleConfirmationWindow();
    } catch (error) {
      enqueueSnackbar(error.response.data.detail.response.Error.Message || error.message, {
        variant: 'error',
      });
    }
  };

  const updateExisting = async (data: UpdateSite) => {
    if (!customerId || !siteId) {
      enqueueSnackbar('CustomerId is missing', {
        variant: 'error',
      });
      return;
    }
    if (error) {
      enqueueSnackbar('Site name invalid', {
        variant: 'error',
      });
      return;
    }
    try {
      await SitesService.edit({ ...data, id: siteId }, customerId, siteId);
      enqueueSnackbar(EDIT_SITE_SNACKBAR_SUCCESS_LABEL, {
        variant: 'success',
      });
      navigate(SITES_MANAGEMENT_PATH + '?customerId=' + customerId);
    } catch (error) {
      console.error(error);
    }
  };

  const methods = useForm<NewSiteWithActualSiteType>({
    resolver: yupResolver(SiteSchema),
    defaultValues,
  });

  const {
    reset,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const theme = useTheme();

  useEffect(() => {
    reset(defaultValues);
    setLastEdited(formatUTCDateToLocal(false, currentSite?.updatedAt));
  }, [reset, defaultValues, currentSite, setLastEdited]);

  const onSiteNameChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    const regex =
      /[\\!\\@\\#\\$\\%\\^\\&\\*\\)\\(\\+\\=\\.\\<\\>\\{\\}\\[\]\\:\\;\\'\\"\\|\\~\\`\\_\\-]/g;

    if (!newValue.match(regex)) {
      setError('');
    } else {
      setError('Special characters not allowed');
    }
    setName(newValue);
  };

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit((data) =>
        allowedToEdit ? updateExisting(data) : createNewSite(data)
      )}
    >
      <Grid container spacing={3} sx={{ my: '0px', mx: '0px', width: '100%' }}>
        <Card sx={{ p: 3, width: '100%', backgroundColor: theme.palette.background.default }}>
          <Grid item xs={12} md={12}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <RHFTextField
                name="siteName"
                label="Site Name*"
                disabled={isSiteEmployee}
                testId="site-name-input"
                value={name}
                helperText={error}
                error={!!error}
                onChange={(e) => onSiteNameChange(e)}
                onBlur={(event) => setValue('siteName', event.target.value.trim())}
              />

              {!isMobile ? (
                <RHFAutocomplete
                  name="siteType"
                  disableClearable
                  disabled={isSiteEmployee}
                  options={siteTypes.map((type) => type.value)}
                  getOptionLabel={(option: string) => {
                    const values = siteTypes.map((type) => type.value);
                    const labels = siteTypes.map((type) => type.label);
                    const index = values.indexOf(option);
                    return `${labels[index]}`;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Site Type*"
                      inputProps={{
                        ...params.inputProps,
                        'data-sm': 'site-type-input',
                      }}
                    />
                  )}
                />
              ) : (
                <RHFSelect
                  name="siteType"
                  options={siteTypes}
                  testId="site-type-input"
                  label="Site Type*"
                />
              )}
              <RHFTextField
                name="location.street"
                label="Street"
                testId="street-input"
                disabled={isSiteEmployee}
              />
              <RHFTextField
                name="location.city"
                label="City*"
                disabled={isSiteEmployee}
                testId="city-input"
                onBlur={(event) => setValue('location.city', event.target.value.trim())}
              />
              <RHFTextField
                name="location.zip"
                label="Zip Code"
                testId="zip-code-input"
                disabled={isSiteEmployee}
              />
              <RHFTextField
                name="location.state"
                label="State*"
                disabled={isSiteEmployee}
                testId="state-code-input"
                onBlur={(event) => setValue('location.state', event.target.value.trim())}
              />
              <RHFAutocomplete
                name="location.country"
                disabled={isSiteEmployee}
                options={countries.map((country) => country.label)}
                onChange={(event, newValue) =>
                  newValue === null
                    ? setValue('location.country', '')
                    : setValue('location.country', newValue as string)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Countries"
                    inputProps={{
                      ...params.inputProps,
                      'data-sm': 'country-input',
                    }}
                  />
                )}
              />
            </Box>
            <Box rowGap={3} columnGap={2} display="grid">
              <Grid item xs={12} md={12}>
                <Box component="div" sx={{ my: '24px' }}>
                  <Accordion
                    defaultExpanded={true}
                    sx={{ backgroundColor: theme.palette.background.default }}
                  >
                    <AccordionSummary
                      expandIcon={<ArrowDownIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      sx={{ width: '180px' }}
                      data-sm="advanced-button-input"
                    >
                      <Typography variant="h5" component="h2">
                        Advanced
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box
                        rowGap={3}
                        columnGap={2}
                        display="grid"
                        gridTemplateColumns={{
                          xs: 'repeat(1, 1fr)',
                          sm: 'repeat(2, 1fr)',
                        }}
                      >
                        <RHFTextField
                          name="location.altitude"
                          label="Altitude"
                          testId="altitude-input"
                          disabled={isSiteEmployee}
                        />
                        <RHFTextField
                          name="location.longitude"
                          label="Longitude"
                          testId="longitude-input"
                          disabled={isSiteEmployee}
                        />
                        <RHFTextField
                          name="location.latitude"
                          label="Latitude"
                          testId="latitude-input"
                          disabled={isSiteEmployee}
                        />
                        <RHFAutocomplete
                          name="location.timeZone"
                          disabled={isSiteEmployee}
                          options={timeZones.map((timeZone) => timeZone.text)}
                          onChange={(event, newValue) =>
                            newValue === null
                              ? setValue('location.timeZone', '')
                              : setValue('location.timeZone', newValue as string)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Time Zone"
                              inputProps={{
                                ...params.inputProps,
                                'data-sm': 'time-zone-input',
                              }}
                            />
                          )}
                        />
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Grid>
            </Box>

            <Stack
              justifyContent="flex-end"
              flexDirection={isMobile ? 'column-reverse' : 'row'}
              sx={{ mt: 3 }}
            >
              {isEdit && currentSite ? (
                <Box
                  component="div"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    margin: isMobile ? '15px auto' : '0 48px 0 0',
                  }}
                >
                  <Typography variant="body2" color="text.secondary">
                    Last edited on: &nbsp;
                  </Typography>
                  <Typography variant="subtitle2" color="text.secondary">
                    {lastEdited}
                  </Typography>
                </Box>
              ) : null}
              <Button
                to={SITES_MANAGEMENT_PATH + '?customerId=' + customerId}
                component={Link}
                variant="outlined"
                sx={{ mr: isMobile ? 0 : 1, mt: isMobile ? 1 : 0 }}
                data-sm="cancel-button"
              >
                Cancel
              </Button>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
                data-sm={isEdit ? 'save-changes-button' : 'add-site-button'}
              >
                {!isEdit ? 'Add Site' : 'Save Changes'}
              </LoadingButton>
            </Stack>
          </Grid>
        </Card>
      </Grid>
    </FormProvider>
  );
};
