import React, {
  JSXElementConstructor,
  ReactElement,
  cloneElement,
  useLayoutEffect,
  useState,
} from 'react';
import { Box, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DoubleLeftArrowIcon, HamburgerIcon } from 'src/assets';
import {
  sideBarHeader,
  mainContent,
  mainContentMap,
  StyledPageContent,
  iconBox,
  mapIconBox,
  StyledMainTop,
  StyledAppLayout,
} from './style';
import { Header } from '../header/Header';
import { SideBar } from '../sideBar';
import { HeaderNavItemType, NavSection } from 'src/features';
import { useToggle, useResponsive } from 'src/hooks';
import { useLocation, useNavigate } from 'react-router';
import { ROOT_PATH, SITES_MANAGEMENT_PATH, SITES_MAP_PATH } from 'src/routes/config';
import { useAuthentication } from 'src/features/authentication/context';
import { StreametricLogo } from 'src/assets/icons/StreametricLogo';
import { useCustomer } from 'src/contexts/customerContext/customer.context';
import { useCustomerAssets } from 'src/contexts/assetContext/asset.context';
import SidebarToggleContextProvider from './sidebarToggle.context';
import { ExtendedNavItemTypes } from '../extendedHeader/types';
import { CustomerThemeIds } from 'src/contexts/customerContext/types';

const MemoizedSideBar = React.memo(SideBar);

type AppLayoutProps = {
  children: React.ReactNode;
  isTitleEditable?: boolean;
  headerTitle?: string;
  headerNavItems: HeaderNavItemType[];
  onNavigateBackClick?: () => void;
  extendedHeaderTitle?: string;
  isSiteHeader?: boolean;
  isSuperAdmin?: boolean;
  onTitleChange?: (title: string) => void;
  isCustomerAccountPage?: boolean;
  extendedNavItems?: ExtendedNavItemTypes[];
  activeExtendedNavItem?: string;
  isExtendedHeader?: boolean;
  defaultExtendedHeaderTitle?: string;
};

export const AppLayout = ({
  children,
  headerTitle,
  headerNavItems,
  isSuperAdmin,
  isTitleEditable,
  extendedHeaderTitle,
  defaultExtendedHeaderTitle,
  activeExtendedNavItem,
  isCustomerAccountPage,
  isSiteHeader = false,
  extendedNavItems,
  onTitleChange,
  onNavigateBackClick,
  isExtendedHeader = false,
}: AppLayoutProps) => {
  const theme = useTheme();

  const whiteColor = theme.palette.common.white;
  const [logoUrl, setLogoUrl] = useState<string>('');

  const doubleLeftArrowIconColor = isSuperAdmin ? whiteColor : theme.palette.text.secondary;

  const isMobile = useResponsive('down', 'md');
  const { state, onToggle } = useToggle();
  const navigate = useNavigate();
  const {
    getCurrentRole,
    getAllCustomerIds,
    customerId: { value: customerId },
  } = useAuthentication();
  const { uniqueIds } = { ...getAllCustomerIds() };
  const location = useLocation();
  const validCustomerId = customerId ?? '';

  const { themeIds, loading, customerName } = useCustomer(validCustomerId);

  const { customerAssets, loadingAssets } = useCustomerAssets(
    themeIds?.value.activeThemeId,
    validCustomerId
  );
  const handleLogoClick = () => {
    if (!customerId || getCurrentRole(null, null) === 'SUPER_ADMIN') {
      navigate(ROOT_PATH);
      return;
    }

    navigate(SITES_MANAGEMENT_PATH + '?customerId=' + customerId);
  };
  const handleCloseSideBar = () => {
    if (state) onToggle();
  };

  useLayoutEffect(() => {
    if (customerAssets?.logo) {
      setLogoUrl(customerAssets?.logo);
    }
  }, [customerAssets?.logo, location.pathname]);
  const greyColorBold = theme.palette.text.secondary;
  const isSiteMap = location.pathname === SITES_MAP_PATH;
  const hederIconStyles = isSiteMap ? mapIconBox : iconBox;
  const mainContentStyles = isSiteMap ? { ...mainContent, ...mainContentMap } : mainContent;
  const showHeaderComponent = (isSiteMap && !isMobile) || !isSiteMap;
  const cloneChildren = isSiteMap && isMobile;

  const renderStreametricLogo = () => <StreametricLogo />;
  const renderCustomLogo = (logo: string) => (
    <Box
      component="img"
      rel="preload"
      src={logoUrl}
      width={200}
      height={100}
      sx={{ border: 'none' }}
    />
  );

  const renderLogo = (
    loading: boolean,
    themeIds: CustomerThemeIds | undefined,
    customerId: string,
    logoUrl: string | undefined,
    path: string
  ) => {
    if (path === '/') {
      return renderStreametricLogo();
    }
    if (loading && !themeIds?.activeThemeId && !logoUrl) {
      return <></>;
    }
    if (!loading && customerId && logoUrl) {
      return renderCustomLogo(logoUrl);
    } else if (!loading && !themeIds?.activeThemeId && customerId && !logoUrl) {
      return renderStreametricLogo();
    }
  };
  const SiteHeader = () => {
    let currentRole;
    if (getCurrentRole) {
      currentRole = getCurrentRole(null, null);
    }

    return (
      <SidebarToggleContextProvider toggle={state} onToggle={onToggle}>
        <StyledMainTop isTransparent={isMobile && isSiteMap} theme={theme} isMobile={isMobile}>
          {isMobile && !isExtendedHeader && !isSiteHeader ? (
            <Box sx={hederIconStyles} onClick={onToggle} data-sm="side-bar-open">
              <HamburgerIcon fill={greyColorBold} />
            </Box>
          ) : null}
          <Header
            extendedHeaderTitle={extendedHeaderTitle}
            defaultExtendedHeaderTitle={defaultExtendedHeaderTitle}
            isTitleEditable={isTitleEditable}
            activeExtendedNavItem={activeExtendedNavItem}
            onTitleChange={onTitleChange}
            isSiteHeader={isSiteHeader}
            onNavigateBackClick={onNavigateBackClick}
            extendedNavItems={extendedNavItems}
            isExtendedHeader={isExtendedHeader}
            title={headerTitle}
            isTransparent={isMobile && isSiteMap}
            showButton={currentRole !== 'SUPER_ADMIN' && uniqueIds ? uniqueIds.length > 1 : false}
          />
        </StyledMainTop>
      </SidebarToggleContextProvider>
    );
  };

  if (loadingAssets) return <></>;

  return (
    <StyledAppLayout>
      <MemoizedSideBar
        isOpen={state}
        isMobile={isMobile}
        isSuperAdmin={isSuperAdmin}
        isCustomerAccountPage={isCustomerAccountPage}
      >
        <Box sx={sideBarHeader}>
          <Box onClick={handleLogoClick} sx={{ cursor: 'pointer' }}>
            {renderLogo(
              loadingAssets,
              themeIds?.value,
              validCustomerId,
              logoUrl,
              location.pathname
            )}
          </Box>
          {isMobile ? (
            <Stack
              flexDirection={'column'}
              justifyContent={'center'}
              onClick={onToggle}
              data-sm="side-bar-close"
            >
              <DoubleLeftArrowIcon height={17} width={20} fill={doubleLeftArrowIconColor} />
            </Stack>
          ) : null}
        </Box>
        <NavSection
          navHeaderItems={headerNavItems}
          handleCloseSideBar={handleCloseSideBar}
          isSuperAdmin={isSuperAdmin}
          isCustomerAccountPage={isCustomerAccountPage}
          customerLoading={loading}
          customerName={customerName}
        />
      </MemoizedSideBar>

      <Box
        component="main"
        sx={{ ...mainContentStyles, backgroundColor: theme.palette.background.paper }}
        onClick={handleCloseSideBar}
      >
        {showHeaderComponent ? <SiteHeader /> : null}
        <StyledPageContent
          theme={theme}
          isMobile={isMobile}
          component="section"
          noPadding={isSiteMap}
        >
          {cloneChildren
            ? cloneElement(children as ReactElement<any, string | JSXElementConstructor<any>>, {
                SiteHeader: SiteHeader,
              })
            : children}
        </StyledPageContent>
      </Box>
    </StyledAppLayout>
  );
};
