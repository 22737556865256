import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { CardContent, Typography, Box, useTheme } from '@mui/material';
import { AlarmWarningIcon, OfflineIcon } from 'src/assets';
import { useSite } from 'src/contexts/siteContext/sites.context';
import { SiteInterface } from 'src/features/sites/types/site';
import { NewSite } from 'src/features/sites/types/sites';
import { SitesService } from 'src/services/sites';
import {
  cardContent,
  cardHeader,
  iconBox,
  StyledCard,
  cardHeaderLeft,
  cardHeaderRight,
} from './style';
import { useAuthentication } from 'src/features/authentication/context';
import { SITES_DASHBOARD_PATH } from 'src/routes/config';
import { WarningIcon, InfoIcon } from 'src/features/theme/overrides/CustomIcons';
import { AlarmPriorityType } from 'src/features/alarm-rules/types';
import { GetDirectionsButton } from '../../get-directions-button';
import { SiteTypeIcon } from '../../site-type-icon';
import { TagsTypes } from 'src/features/sites/types/types';
import { NoLiveDataSiteCard } from '../../no-live-data-site-card';
import { TagsList } from '../../tags-list';

type MobileSiteCardProps = {
  site: NewSite;
  itemRefs: React.MutableRefObject<(HTMLDivElement | null)[]>;
  index: number;
  handleDialogOpen: (siteId: null | string) => void;
  tags: TagsTypes;
  roleAccessInfoBox: boolean;
  liveData: any;
};

export const MobileSiteCard = ({
  site,
  itemRefs,
  index,
  tags,
  handleDialogOpen,
  roleAccessInfoBox,
  liveData,
}: MobileSiteCardProps) => {
  const theme = useTheme();
  const noDataAndShowDataTrue = !tags?.tags?.length && tags?.showLiveData === true;
  const displayNoLiveDataLabel = !tags || noDataAndShowDataTrue;

  const [noLiveDataLabel, setNoLiveDataLabel] = useState<boolean>(displayNoLiveDataLabel);

  useEffect(() => {
    setNoLiveDataLabel(displayNoLiveDataLabel);
  }, [displayNoLiveDataLabel]);

  const liveDateForSite = liveData?.filter((item: any) => item.data.site_id === site?.id) || [];
  const combinedGatewayInputs = liveDateForSite.reduce(
    (acc: any, item: any) => acc.concat(item.data.gateway_inputs),
    []
  );

  const liveDataComplete = {
    siteId: liveDateForSite?.site_id,
    tags: liveDateForSite && liveDateForSite.length > 0 ? combinedGatewayInputs : undefined,
  };

  const {
    customerId: { value: customerId },
  } = useAuthentication();
  const navigate = useNavigate();
  const { saveSite } = useSite();

  const handleRedirect = (siteId: string) => {
    if (!customerId) return;
    navigate(SITES_DASHBOARD_PATH + `?customerId=${customerId}&siteId=${siteId}`);
  };

  const removeNoLiveDataLabelHandler = () => {
    setNoLiveDataLabel(false);
  };

  const handleRowClick = () => {
    if (!customerId) return;
    SitesService.getById({ customerId: customerId, siteId: site.id })
      .then((data: SiteInterface) => {
        saveSite(data);
      })
      .catch((error) => console.error(error));
    handleRedirect(site.id);
  };

  const handleAlarmView = (priority: AlarmPriorityType | undefined) => {
    switch (priority) {
      case 'Critical':
        return <AlarmWarningIcon fill={theme.palette.error.main} />;
      case 'Warning':
        return <WarningIcon aria-label="alarm-warning" htmlColor={theme.palette.warning.main} />;
      case 'Info':
        return <InfoIcon aria-label="alarm-info" htmlColor={theme.palette.secondary.main} />;
      case 'Offline':
        return <OfflineIcon fill={theme.palette.error.main} />;
      default:
        return '-';
    }
  };
  const siteNameLength = site.siteName.length;

  return (
    <StyledCard ref={(el) => (itemRefs.current[index] = el)} theme={theme} onClick={handleRowClick}>
      <CardContent sx={cardContent}>
        <Box sx={cardHeader}>
          <Box sx={cardHeaderLeft}>
            <Box sx={iconBox}>
              <SiteTypeIcon siteType={site?.siteType} />
            </Box>
            <Typography data-sm="site-name-row" variant="subtitle1">
              {site.siteName.substring(0, 50)}
              {siteNameLength > 50 && <Typography component="span">...</Typography>}
            </Typography>
          </Box>
          <Box data-sm="open-issues-row" sx={cardHeaderRight}>
            {handleAlarmView(site.highestActiveAlarm)}
            <GetDirectionsButton row={site} handleEditSitePreview={handleDialogOpen} />
          </Box>
        </Box>

        <Box>
          {noLiveDataLabel && roleAccessInfoBox ? (
            <NoLiveDataSiteCard
              isMobile={true}
              testId="no-live-data-site-card"
              handleEditSitePreview={handleDialogOpen}
              siteId={site?.id ?? null}
              customerId={customerId}
              removeNoLiveDataLabelHandler={removeNoLiveDataLabelHandler}
            />
          ) : (
            <TagsList
              liveDataComplete={liveDataComplete}
              isMobile={true}
              tags={tags}
              testId="tags-list"
            />
          )}
        </Box>
      </CardContent>
    </StyledCard>
  );
};
