import FloatingAddLog from '../components/FloatingAddLog';
import LogForm from '../components/LogForm';
import { CustomWaterLoading, MenuPopoverItem } from 'src/components';
import { useAuthentication } from 'src/features/authentication/context';
import { useEffect, useRef, useState, useCallback } from 'react';
import { ApiState, FilterItem, LogItem } from '../types';
// import {FilterItem} from "../types"
import { Container, Dialog, Stack } from '@mui/material';
import LogsList from '../components/LogsList';
import LogFilters from '../components/LogFilters';
import useQueryParamsActions from 'src/hooks/useQueryParamsActions';
import { useResponsive } from 'src/hooks';
import { LogsService } from 'src/services/logs';
import parseLogPayload from '../utils/parseLogPayload';
import { useSnackbar } from 'notistack';
import RestoreLogConfirmDialog from '../components/RestoreLogConfirmDialog';
import NoLogsPlaceholder from '../components/NoLogsPlaceholder';
import getAttachments from '../utils/getAttachments';
import LogsPagination from '../components/LogsPagination';
import { MobileAlarmPopUp } from 'src/features/alarm-pop-up/components/mobile/MobileAlarmPopUp';
import { DesktopAlarmPopUp } from 'src/features/alarm-pop-up/components/desktop/DesktopAlarmPopUp';
import { AlarmsService } from 'src/services';
import { AlarmRuleService } from 'src/services/alarmRules';
import { SitesService } from 'src/services/sites';

const Logs = () => {
  const {
    customerId: { value: customerId },
    siteId: { value: siteId },
  } = useAuthentication();
  const filtersRef = useRef<any>('');
  const [filters, setFilters] = useState('');
  const [logs, setLogs] = useState<{
    loaded: boolean;
    data: { totalCount: number; results: LogItem[] };
    fetched: boolean;
  }>({
    loaded: false,
    fetched: false,
    data: {
      results: [],
      totalCount: 0,
    },
  });
  const isMobile = useResponsive('down', 'md');
  const [alarm, setAlarm] = useState<any>({});
  const [isAlarmDataLoding, setIsAlarmDataLoading] = useState(false);
  const [alarmOpen, setAlarmOpen] = useState(false);
  const [alarmRule, setAlarmRule] = useState<any>(null);
  const [notifications, setNotifications] = useState<any>(null);
  const { append, get, remove } = useQueryParamsActions();
  const [isCreatingLog, setIsCreatingLog] = useState(false);
  const [isDeletingLog, setIsDeletingLog] = useState(false);
  const [isRevertingLog, setIsRevertingLog] = useState(false);

  const [logToRevert, setLogToRevert] = useState<null | LogItem>(null);

  const [formsState, setFormsState] = useState<
    ApiState<
      {
        name: string;
        id: string;
        allowComments: boolean;
        isDefault: boolean;
      }[]
    >
  >({
    loading: true,

    data: [],
  });
  const formId = get('formId');
  const logId = get('logId');
  const version = get('version');
  const form = formsState.data.find((form) => form.id === formId);
  const fetchedFormsRef = useRef<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const onClose = useCallback(() => {
    remove('formId');
    remove('logId');
    remove('version');
  }, [remove]);

  const fetchLogs = useCallback(
    async (filters = '', lastLogId?: string) => {
      try {
        if (!customerId || !siteId) throw Error('Customer or site is not provided.');
        setLogs((prevState) => ({
          ...prevState,
          loaded: lastLogId ? true : false,
          fetched: lastLogId ? false : true,
        }));
        const logs = await LogsService.getAll(customerId!, siteId!, filters, lastLogId);
        if (lastLogId) {
          setLogs((prevState) => ({
            data: {
              results: [...(prevState.data?.results || []), ...logs.results],
              totalCount: logs.total_count,
            },
            fetched: true,
            loaded: true,
          }));
        } else {
          setLogs({
            data: {
              results: logs.results,
              totalCount: logs.total_count,
            },
            loaded: true,
            fetched: true,
          });
        }
      } catch (err) {
        setLogs({ data: { results: [], totalCount: 0 }, loaded: true, fetched: true });
      }
    },
    [setLogs, customerId, siteId]
  );

  const onFiltersChange = useCallback(async (filter: FilterItem[]) => {
    const filters: string[] = [];

    const eventTypeList: string[] = [];
    const alarmStatusList: string[] = [];
    const alarmPriorityList: string[] = [];
    const alarmAcknowledgement: string[] = [];

    filter.forEach((filt) => {
      if (filt.name === 'deleted_logs') {
        filters.push('includeDeletedLogs=true');
      }
      console.log(filt.name);
      if (filt.name === 'time_frame') {
        filters.push('startDate=' + (filt!.value as string[])[0]);
        filters.push('endDate=' + (filt!.value as string[])[1]);
      }
      if (filt.group.name === 'alarm_type') {
        alarmPriorityList.push(filt.name);
      }
      if (filt.group.name === 'alarm_acknowledgement') {
        alarmAcknowledgement.push(filt.name);
      }
      if (filt.group.name === 'event_type' && filt.value === true) {
        eventTypeList.push(filt.name);
      }
      if (filt.group.name === 'alarm_status' && filt.value === true) {
        alarmStatusList.push(filt.name);
      }

      if (filt.name === 'assets' && Array.isArray(filt.value)) {
        filt.value.forEach((et: any) => filters.push('assetIdList=' + et.id));
      }
    });

    if (eventTypeList.length) {
      eventTypeList.forEach((et) => filters.push(`eventTypeList=${et}`));
    }
    if (alarmAcknowledgement.length === 1) {
      if (alarmAcknowledgement.includes('acknowledged')) {
        alarmAcknowledgement.forEach((et) => filters.push(`isAcknowledged=true`));
      } else {
        alarmAcknowledgement.forEach((et) => filters.push(`isAcknowledged=false`));
      }
    }

    if (alarmPriorityList.length) {
      alarmPriorityList.forEach((et) => filters.push(`alarmPriorityList=${et}`));
    }
    if (alarmStatusList.length) {
      alarmStatusList.forEach((et) => filters.push(`alarmStatusList=${et}`));
    }

    setFilters(filters.join('&'));
  }, []);

  // Log operations
  const handleCreateLog = async (data: any, logFormId?: string, allowComments?: boolean) => {
    if (!form?.id && !logFormId) return;

    const log: any = parseLogPayload(
      data,
      (form?.id || logFormId) as string,
      !!allowComments,
      logId
    );

    const attachments = getAttachments(data);

    if (!customerId || !siteId) return;

    const maxSizeInMB = 10; // 10 MB
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024; // Convert MB to Bytes

    let hasTooLongFile = false;
    Object.values(attachments).forEach((attachment: any) => {
      if (Array.isArray(attachment)) {
        attachment.forEach((file: any) => {
          if (file?.size > maxSizeInBytes) {
            hasTooLongFile = true;
          }
        });
      }
    });

    if (hasTooLongFile)
      return enqueueSnackbar('Uploaded file size is too long! (Maximum is: 10mb)', {
        variant: 'error',
      });

    try {
      setIsCreatingLog(true);
      await LogsService.add(customerId, siteId, log, attachments, logId ?? undefined);
      await fetchLogs(filtersRef.current);
      enqueueSnackbar('Log successfully created!', { variant: 'success' });
      setIsCreatingLog(false);
      onClose();
    } catch (err) {
      enqueueSnackbar('Log creation failed!', { variant: 'error' });
      setIsCreatingLog(false);
    }
  };
  const handleDeleteLog = async (logId: string) => {
    try {
      setIsDeletingLog(true);
      await LogsService.patch(customerId!, siteId!, logId, { status: 'inactive' });

      enqueueSnackbar('Log successfully deleted!', { variant: 'success' });
      setIsDeletingLog(false);
      onClose();
      await fetchLogs(filtersRef.current);
    } catch (err) {
      enqueueSnackbar('Log deletion failed! Message', { variant: 'error' });
      setIsDeletingLog(false);
    }
  };
  const handleRevertLog = async (logId: string) => {
    try {
      setIsRevertingLog(true);
      await LogsService.patch(customerId!, siteId!, logId, { status: 'active' });

      enqueueSnackbar('Log successfully reverted!', { variant: 'success' });
      setIsRevertingLog(false);
      setLogToRevert(null);
      await fetchLogs(filtersRef.current);
    } catch (err) {
      enqueueSnackbar('Log revert failed! Message', { variant: 'error' });
      setIsRevertingLog(false);
      setLogToRevert(null);
    }
  };
  const handleSelectAlarm = useCallback(
    async (eventId: string, siteId: string) => {
      if (!customerId) return;
      setIsAlarmDataLoading(true);
      try {
        if (siteId !== '' && eventId !== '') {
          const alarm = await AlarmsService.getAlarmPopupInfo(customerId, siteId, eventId);
          setAlarm(alarm);
          const alarmRule = await AlarmRuleService.getById(customerId, siteId, alarm.ruleId);
          setAlarmRule(alarmRule);
          setAlarmOpen(true);
        }
        const notifications = await AlarmsService.getAlarmPopupNotifications(customerId, eventId);
        setNotifications(notifications);
        setIsAlarmDataLoading(false);
      } catch (err) {
        setAlarm({});
        setAlarmRule({});
        setNotifications([]);
        setIsAlarmDataLoading(false);
      }
    },
    [customerId, setAlarm, setAlarmRule, setNotifications]
  );

  useEffect(() => {
    filtersRef.current = filters;

    fetchLogs(filters);
  }, [fetchLogs, filters]);

  useEffect(() => {
    if (!fetchedFormsRef.current) {
      (async () => {
        const data = await SitesService.getAssignedForms(customerId!, siteId!);
        setFormsState({ loading: false, data });
        fetchedFormsRef.current = true;
      })();
    }
  }, [customerId, siteId]);

  const formsNotAvailable = !formsState.loading && (!formsState.data || !formsState.data.length);

  return (
    <Container
      maxWidth={false}
      sx={{
        paddingInline: '0px !important',
        display: 'flex',
        height: isMobile ? '100%' : 'calc(100% - 138px)',
        flexDirection: 'column',
      }}
    >
      <LogFilters onChange={onFiltersChange} />

      <Dialog open={isAlarmDataLoding}>
        <CustomWaterLoading />
      </Dialog>

      {isMobile ? (
        <MobileAlarmPopUp
          open={alarmOpen}
          onClose={() => {
            setAlarmOpen(false);
            setAlarm({});
            setAlarmRule({});
            setNotifications([]);
          }}
          customerId={customerId!}
          siteId={siteId!}
          alarmData={alarm}
          setAlarmData={setAlarm}
          alarmRule={alarmRule}
          notifications={notifications ?? []}
          setRefreshTrigger={() => {}}
          onAcknowledged={(response) => {
            setLogs((prevState) => ({
              ...prevState,
              data: {
                ...prevState.data,
                results: prevState.data.results.map((item) =>
                  item.id === response.id
                    ? { ...item, acknowledgment: response.acknowledgment }
                    : item
                ),
              },
            }));
          }}
          onAcknowledgedChange={() => {}}
        />
      ) : (
        <DesktopAlarmPopUp
          open={alarmOpen}
          onClose={() => {
            setAlarmOpen(false);
            setAlarm({});
            setAlarmRule({});
            setNotifications([]);
          }}
          customerId={customerId!}
          siteId={siteId!}
          alarmData={alarm}
          setAlarmData={() => {}}
          alarmRule={alarmRule}
          notifications={notifications}
          setRefreshTrigger={() => {}}
          onAcknowledged={(response) => {
            setLogs((prevState) => ({
              ...prevState,
              data: {
                ...prevState.data,
                results: prevState.data.results.map((item) =>
                  item.id === response.id
                    ? { ...item, acknowledgment: response.acknowledgment }
                    : item
                ),
              },
            }));
          }}
          onAcknowledgedChange={() => {}}
        />
      )}

      <RestoreLogConfirmDialog
        loading={isRevertingLog}
        onConfirm={async () => handleRevertLog(logToRevert!.id)}
        onClose={() => {
          setLogToRevert(null);
        }}
        open={!!logToRevert}
        logTitle={logToRevert?.title || ''}
      />
      {logs.loaded ? (
        logs.data.results.length ? (
          <>
            {' '}
            <LogsList
              onAlarmSelected={(id: string) => {
                handleSelectAlarm(id, siteId!);
              }}
              onLogRevert={setLogToRevert}
              onLogSelected={(log) => {
                append('logId', log.id);
              }}
              logs={logs.data.results}
            />
            {logs.fetched ? (
              logs.data.results.length < logs.data.totalCount ? (
                <LogsPagination
                  loadedItemsCount={logs.data.results.length}
                  onLoadMore={() => {
                    const lastLog = logs.data.results[logs.data.results.length - 1];
                    fetchLogs(filtersRef.current, lastLog.id);
                  }}
                  totalCount={logs.data.totalCount}
                />
              ) : (
                <></>
              )
            ) : (
              <Stack
                sx={{ width: '100%', maxWidth: '100%', paddingBottom: '60px' }}
                justifyContent="center"
                alignItems="center"
              >
                <CustomWaterLoading />
              </Stack>
            )}
          </>
        ) : (
          <NoLogsPlaceholder />
        )
      ) : (
        <CustomWaterLoading />
      )}

      {/* Form modal */}
      {form || logId ? (
        <LogForm
          onChangeVersionHistory={(historyItem, lastItem) => {
            if (lastItem && lastItem.version === historyItem.version) return remove('version');
            append('version', historyItem.version + '');
          }}
          version={version ?? undefined}
          isCreatingLog={isCreatingLog}
          isDeletingLog={isDeletingLog}
          logId={logId}
          onSubmit={handleCreateLog}
          onDelete={handleDeleteLog}
          onClose={onClose}
          isDefault={form?.isDefault}
          id={form?.id}
        />
      ) : (
        <></>
      )}
      <FloatingAddLog
        disabled={formsState.loading || formsNotAvailable}
        renderActions={(onClose) => (
          <>
            {formsState.data.map((form) => (
              <MenuPopoverItem
                key={form.id}
                handleClick={() => {
                  onClose();

                  remove('logId');
                  append('formId', form.id);
                }}
                customContent={<>{form.name}</>}
              />
            ))}
          </>
        )}
      />
    </Container>
  );
};

export default Logs;
