import { Container } from '@mui/material';
import { AddWidgetBox } from '../../components';

export const SiteTrends = () => {
  console.log('site trends');
  return (
    <Container maxWidth={false} disableGutters data-sm="site-trends-container">
      <AddWidgetBox
        fullWidth
        title="Add Chart"
        onToggle={function (): void {
          throw new Error('Func');
        }}
      />
    </Container>
  );
};
