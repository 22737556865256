import { Box, Skeleton, Stack, Typography, useTheme } from '@mui/material';
import { FunctionComponent } from 'react';
import { numOfItemsBox, StyledLoadMoreButton } from 'src/layouts/alarmMobileLayout/style';

const LogsPagination: FunctionComponent<{
  totalCount: number;
  loadedItemsCount: number;
  onLoadMore: () => void;
}> = ({ totalCount, onLoadMore, loadedItemsCount }) => {
  const theme = useTheme();
  return (
    <>
      <Box sx={numOfItemsBox}>
        <Typography variant="body2">
          Displaying: {loadedItemsCount} of {totalCount}
        </Typography>
      </Box>

      <Stack justifyContent="center" alignItems="center">
        <Skeleton animation={'wave'} variant="rounded" width={'75%'} height={'22px'} />
      </Stack>
      <Stack justifyContent="center" alignItems="center" paddingBottom="60px">
        <StyledLoadMoreButton variant="contained" size="large" theme={theme} onClick={onLoadMore}>
          Load More
        </StyledLoadMoreButton>
      </Stack>
    </>
  );
};

export default LogsPagination;
